import { observer } from "mobx-react";
import * as React from "react";
import { Route, RouteChildrenProps, Switch } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { doctorStore } from "../../../stores/DoctorStore";
import { StandardContainer } from "../../containers/StandardContainer";
import { Routes } from "../../routers/Routes";
import { UserNavigation } from "../../ui/UserNavigation";
import { Colors } from "../../util/Colors";
import { Icon } from "../../util/Icon";
import { MedicationCheck } from "../shared/components/MedicationCheck";
import { MedicationGroups } from "../shared/components/MedicationGroups";

const NavigationHeading = styled.h4`
    margin-left: 32px;
`;

const BackContainer = styled.div`
    display: flex;
    align-items: center;
`;

type IParams = {
    uid?: string;
};

type IProps = RouteChildrenProps<IParams>;

export const MedicationSite = observer(({ match }: IProps) => {
    const uid = match?.params.uid;
    const patient = doctorStore.selectedPatient?.uid === uid ? doctorStore.selectedPatient : null;

    React.useEffect(() => {
        if (!patient && uid) {
            doctorStore.loadPatient(uid);
        }
    }, [uid]);

    if (!patient || !uid) {
        return null;
    }

    const backLink = patient.canOptimize ? Routes.DOCTOR.OPTIMIZE.replace(":uid", uid) : Routes.DOCTOR.ROOT;

    return (
        <>
            <UserNavigation
                leftComponent={
                    <BackContainer>
                        <Link to={backLink}>
                            <Icon name="arrowLeft" hoverColor={Colors.secondary} />
                        </Link>
                        <NavigationHeading>
                            {patient.lastname} {patient.firstname}
                        </NavigationHeading>
                    </BackContainer>
                }
            />
            <StandardContainer>
                <Switch>
                    <Route path={Routes.DOCTOR.MEDICATION_GROUPS} component={MedicationGroups} />
                    <Route path={Routes.DOCTOR.MEDICATION_CHECK} component={MedicationCheck} />
                </Switch>
            </StandardContainer>
        </>
    );
});
